import * as React from 'react'
import { Box, Button, Container, CssBaseline, Divider, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import TitleBar from "../TitleBar/TitleBar";
import theme from '../theme';

export function BucketListHome() {
    const [showNSFW, setShowNSFW] = React.useState(false)
    const onClick = () => setShowNSFW(true)
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <TitleBar />
                <Container>

                    <Typography variant="h3">The Bucket List</Typography>
                    <Typography>In 2021, I started writting new year lists of things I wanted to achieve. As I kept this up I decided to make them more fun, and scarily, public</Typography>
                    <Typography>So below is my bucket list! If you want to join me on any of these fun, interesting, mundane, common, crazy ideas then let me know</Typography>
                    <Typography variant="subtitle1">PS: I'll update these as I go as well</Typography>
                    <Typography variant="subtitle1">PPS: More fun at the bottom</Typography>

                    <Divider flexItem />
                    <Typography variant="h4">Health ⛰️</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1"> ✅ Climb the UK Three Peaks: Snowdon, Scarfell Pike and Ben Nevis</Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> ✅ Do a 5K</Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> ✅ Do a 10K</Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Do a half marathon</Typography>
                        </li>
                    </ul>

                    <Divider flexItem />
                    <Typography variant="h4">Life 😎</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1"> ✅ Finish the PhD</Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> ✅ Actually publish that stupid website you keep avoiding (hehe, proof is here)</Typography>
                        </li>
                    </ul>

                    <Divider flexItem />
                    <Typography variant="h4">Travel ✈️</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1"> Visit Japan during the spring blossoms </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Visit Petra and Jordan </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> See the Pyramids </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> See the Northern Lights </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Visit every major European Capital (6/21): ✅Amsterdam, Athens, Berlin, Brussels, Budapest, Copenhagen, ✅Dublin, Helsinki, Lisbon, ✅Luxembourg, Madrid, ✅Monaco, Oslo, ✅Paris, Prague, Reykjavik, ✅Rome, Stockholm, Vienna, Warsaw and Zagreb </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Visit these world cities: Barcelona, Venice, Tokyo, Dallas, Washington, New Orleans</Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Go to Yosemite National Park </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Go to Cape Canaveral </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Hike in every UK National Park (7/14): ✅Bannau Brycheiniog, Broads, Cairngorms, ✅Dartmoor, ✅Eryri, Exmoor, ✅Lake District, Lock Lomond & The Trossachs, New Forest, North York Moors, ✅Northumberland, ✅Peak District, Pembrokeshire Coast, ✅South Downs, Yorkshire Dales  </Typography>
                        </li>
                    </ul>

                    <Divider flexItem />
                    <Typography variant="h4">Experiences 🤩</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1"> Go to Glastonbury Music Festival </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Drive a race car on a race circuit </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Watch the Tennis at Roland Garros </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Watch the Monaco Grand Prix </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Watch the Singapore Grand Prix </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Camp at the 24 hours of Le Mans </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Experience a full solar exclipse </Typography>
                        </li>
                        <li>
                            <Typography variant="body1"> Watch a rocket launch </Typography>
                        </li>
                    </ul>

                    <Divider flexItem />
                    <Typography variant="h4">The NSFW bit 🫥</Typography>
                    <Button variant="contained" onClick={onClick}>Show</Button>
                    {showNSFW ? <Typography variant="body1" > Ha. Gotcha, you curious thing! 😉</Typography> : null}

                </Container>

            </Box>
        </ThemeProvider>
    );
}